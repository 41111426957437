
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiTaskManagement, ApiBase, ApiCampaign, ApiChanel } from "@/core/api";
import RefuseValidationModal from "@/views/task-management/account-validation/RefuseValidationModal.vue";
import ShowContentModel from "@/views/task-management/standard-operating-procedure/ShowContentModel.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { setModuleBCN } from "@/core/directive/function/common";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "account-validation-information",
  components: {
    RefuseValidationModal,
    ShowContentModel,
    PermissionCommon,
  },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const { t } = useI18n();

    const formData = ref({
      id: route.params.id,
      system_id: "",
      salutation: "",
      first_name: "",
      last_name: "",
      source: "",
      channel: "",
      industry: "",
      validation_date: "",
      validated_by: "",
      registration_mode: "",
      registration_channel: "",
      invite_by: "",
      recommended_by: "",
      invite_campaign: "",
      membership_level: "",
      validation_notice: "",
      refuse_reason: "",
      standard_operating_procedure_id: "",
      system_status: 0,
      type: "",
      apply_to: [],
      standard_script_id: "",
      suitable_conditions: [],
      // suitable_account: [],
      script: "",
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    //保存验证规则
    const saveRules = ref({
      validated_by: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      registration_mode: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      registration_channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      membership_level: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    // 激活验证规则
    const activeRules = ref({
      salutation: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      first_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      source: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      industry: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      validated_by: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      registration_mode: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      registration_channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      membership_level: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const action = ref("save");

    const options = ref({
      sop: [],
      standard_script: [],
    });

    const showTitle = ref<string>("");
    const showContent = ref<string>("");

    const industryOptions = ref([]);
    const channelOptions = ref([]);
    const sourceOptions = ref([]);

    const influencerOptions = ref([]);
    const influencerLoading = ref<boolean>(false);
    const validatedByOptions = ref([]);
    const validatedByLoading = ref<boolean>(false);
    const registrationModeOptions = ref([]);
    const registrationModeLoading = ref<boolean>(false);
    const registrationChannelOptions = ref([]);
    const registrationChannelLoading = ref<boolean>(false);
    const inviteByOptions = ref([]);
    const inviteByLoading = ref<boolean>(false);
    const recommendedByOptions = ref([]);
    const recommendedByLoading = ref<boolean>(false);
    const inviteCampaignOptions = ref([]);
    const inviteCampaignLoading = ref<boolean>(false);
    const membershipLevelOptions = ref([]);
    const membershipLevelLoading = ref<boolean>(false);
    // SOP and Script
    const typeOptions = ref([]);
    const typeLoading = ref<boolean>(false);
    const applyToOptions = ref([]);
    const applyToLoading = ref<boolean>(false);
    const suitableConditionsOptions = ref([]);
    const suitableConditionsLoading = ref<boolean>(false);
    const suitableAccountOptions = ref([]);
    const suitableAccountLoading = ref<boolean>(false);

    const getSopStandardScriptInfo = async () => {
      const { data } = await ApiTaskManagement.getSopStandardScriptInfo({
        id: route.params.id,
        type: "validation",
      });
      if (data.code == 0) {
        options.value.sop = data.data.sop;
        options.value.standard_script = data.data.standard_script;
      }
    };

    const getTypeLabel = (row, column, cellValue, index) => {
      const type = new Map([
        ["1", "Account Validation"],
        ["2", "Call-Out"],
        ["3", "Quick Inquiry"],
      ]);
      return type.get(String(cellValue));
    };

    const getChannelOptions = () => {
      ApiChanel.getDistributionDataSource({})
        .then(({ data }) => {
          if (data.code == 0) {
            channelOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "distribution_industry",
          "global_account_source",
          "account_registration_mode",
          "account_registration_channel",
          "sop_apply_to",
        ],
      });
      if (data.code == 0) {
        industryOptions.value = data.data.distribution_industry.items;
        sourceOptions.value = data.data.global_account_source.items;
        registrationModeOptions.value =
          data.data.account_registration_mode.items;
        registrationChannelOptions.value =
          data.data.account_registration_channel.items;
        applyToOptions.value = data.data.sop_apply_to.items;
      }
    };

    const getCampaignsSourceData = async () => {
      inviteCampaignLoading.value = true;
      const { data } = await ApiCampaign.getCampaignsSourceData({
        max_item: "all",
      });
      inviteCampaignLoading.value = false;
      if (data.code == 0) {
        inviteCampaignOptions.value = data.data;
      }
    };

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (validatedByLoading.value = true)
            : (validatedByLoading.value = false);
          break;
        case 1:
          status
            ? (inviteByLoading.value = true)
            : (inviteByLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          validatedByOptions.value = data;
          break;
        case 1:
          inviteByOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData(params);
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchValidatedByItems = (query) => {
      debounceUserSearch(0, query);
    };
    const searchInviteByItems = (query) => {
      debounceUserSearch(1, query);
    };

    const switchAccountType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (influencerLoading.value = true)
            : (influencerLoading.value = false);
          break;
        case 1:
          status
            ? (recommendedByLoading.value = true)
            : (recommendedByLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchAccountValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          influencerOptions.value = data;
          break;
        case 1:
          recommendedByOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getAccountSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchAccountType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      switchAccountType(type, false);
      if (data.code == 0) {
        switchAccountValue(type, data.data);
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchAccountItems = (query) => {
      debounceAccountSearch(0, query);
    };
    const searchRecommendedByItems = (query) => {
      debounceAccountSearch(1, query);
    };

    const getInfluencerGroupSourceData = async () => {
      membershipLevelLoading.value = true;
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      membershipLevelLoading.value = false;
      if (data.code == 0) {
        membershipLevelOptions.value = data.data;
      }
    };

    const getAccountValidationInfo = async () => {
      loading.value = true;
      const { data } = await ApiTaskManagement.getAccountValidationInfo({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        if (data.data.recommended_by != "") {
          getAccountSourceData(1, "", data.data.recommended_by);
        }
        if (data.data.validated_by != "") {
          getUserSourceData(0, "", data.data.validated_by);
        } else {
          formData.value.validated_by =
            store.state.AuthModule.user.id.toString();
          getUserSourceData(0, "", store.state.AuthModule.user.id);
        }
        if (data.data.invite_by != "") {
          getUserSourceData(1, "", data.data.invite_by);
        }
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (action.value == "save") {
            updateAccount();
          } else {
            activeAccount();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const onSaveClick = () => {
      action.value = "save";
      formRef.value?.clearValidate();
    };

    const updateAccount = () => {
      loading.value = true;
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      ApiTaskManagement.updateAccountValidation(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg();
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onActiveClick = () => {
      action.value = "active";
      formRef.value?.clearValidate();
    };

    const activeAccount = () => {
      Swal.fire({
        title: "Activate Now?",
        icon: "info",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          loading.value = true;
          ApiTaskManagement.approveAccountValidation(formData.value)
            .then(({ data }) => {
              loading.value = false;
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  getAccountValidationInfo();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const deleteAccountValidation = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Account Validation?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiTaskManagement.deleteAccountValidation({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Account Validation has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "account-validation" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const showContentModel = (title: string, content: string) => {
      showTitle.value = title;
      showContent.value = content;
    };

    onBeforeMount(() => {
      getAccountValidationInfo();
      getChannelOptions();
      getDropdownOptions();
      getCampaignsSourceData();
      getInfluencerGroupSourceData();
      getSopStandardScriptInfo();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      loading,
      formData,
      saveRules,
      activeRules,
      action,
      options,
      formRef,
      submitButton,
      deleteButton,
      industryOptions,
      channelOptions,
      sourceOptions,
      influencerOptions,
      influencerLoading,
      validatedByOptions,
      validatedByLoading,
      registrationModeOptions,
      registrationModeLoading,
      registrationChannelOptions,
      registrationChannelLoading,
      inviteByOptions,
      inviteByLoading,
      recommendedByOptions,
      recommendedByLoading,
      inviteCampaignOptions,
      inviteCampaignLoading,
      membershipLevelOptions,
      membershipLevelLoading,
      // SOP and Script
      typeOptions,
      typeLoading,
      applyToOptions,
      applyToLoading,
      suitableConditionsOptions,
      suitableConditionsLoading,
      suitableAccountOptions,
      suitableAccountLoading,
      showTitle,
      showContent,
      t,
      getTypeLabel,
      searchValidatedByItems,
      searchInviteByItems,
      searchAccountItems,
      searchRecommendedByItems,
      submit,
      onSaveClick,
      onActiveClick,
      deleteAccountValidation,
      showContentModel,
      getAccountValidationInfo,
    };
  },
});
