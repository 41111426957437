import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_show_content",
  ref: "showModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_show_content_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_9 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_show_content_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_show_content_header",
  "data-kt-scroll-wrappers": "#kt_modal_show_content_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "m-0 ms-n4" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "modal-footer flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.props.title), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.getContentType)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("ol", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.content, (item, index) => {
                      return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item.check_list), 1))
                    }), 128))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  innerHTML: _ctx.props.content
                }, null, 8, _hoisted_12))
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleHide && _ctx.handleHide(...args))),
            class: "btn btn-lg btn-primary"
          }, _toDisplayString(_ctx.t("common.close")), 1)
        ])
      ])
    ])
  ], 512))
}