
import { computed, defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";
import { string } from "yup/lib/locale";

export default defineComponent({
  name: "show-content-modal",
  props: {
    content: { type: [String, Array], default: "" },
    title: { type: String, default: "" },
  },
  components: {},
  emits: [],
  setup(props) {
    const { t } = useI18n();

    const showModalRef = ref<null | HTMLElement>(null);

    const getContentType = computed(() => {
      if (props.content instanceof Array) {
        return true;
      } else {
        return false;
      }
    });

    const handleHide = () => {
      hideModal(showModalRef.value);
    };

    return {
      props,
      showModalRef,
      getContentType,
      handleHide,
      t,
    };
  },
});
