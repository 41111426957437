
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiTaskManagement } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "refuse-validation-modal",
  props: {
    id: { type: Number, default: 0 },
  },
  components: {},
  emits: ["update-info"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const refuseValidationModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const { t } = useI18n();

    const formData = ref({
      id: props.id,
      refuse_reason: "",
    });

    const rules = ref({
      refuse_reason: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");
          ApiTaskManagement.refuseAccountValidation(formData.value)
            .then(({ data }) => {
              loading.value = false;
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  hideModal(refuseValidationModalRef.value);
                  emit("update-info");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      refuseValidationModalRef,
      submitButton,
      loading,
      t,
    };
  },
});
